<template>
  <div class="news">
    <BannerKv
      :titleEn="BannerKvData.titleEn"
      :titleZh="BannerKvData.titleZh"
      :backgroundPic="BannerKvData.backgroundPic"/>
    <!-- <NewsSearch/> -->
    <NewsList
      :Classification="Classification"
      :SengdingNewsData="SengdingNewsData"
      :NewsByTagData="NewsByTagData"
      :tagSlug="tagSlug"
      />
    <div class="w1300">
      <Pagination :pageData="pageData" :tagSlug="tagSlug" v-show="pageData.pageCount !== null" />
    </div>
  </div>
</template>

<script>
import '@/assets/scss/news.scss';
import BannerKv from '@/components/BannerKv.vue';
import Pagination from '@/components/Pagination.vue';
// import NewsSearch from '@/components/news/NewsSearch.vue';
import NewsList from '@/components/news/NewsList.vue';

import pic01 from '@/assets/img/index/news_kv.jpg';

import { getNewsTags, getNewsByTag } from '@/lib/public';
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'News',
  components: {
    BannerKv,
    // NewsSearch,
    NewsList,
    // Pagination,
    Pagination,
  },
  props: ['tagSlug'],
  data() {
    return {
      BannerKvData: {
        titleEn: 'NUWA NEWS',
        titleZh: this.$t('news'),
        backgroundPic: pic01,
      },
      NewsByTagData: [],
      SengdingNewsData: [],
      Classification: [
        {
          path: '/news/all/1',
          linkName: this.$t('all'),
          uuid: '123',
        },
      ],
      pageData: {
        currentPage: null,
        pageCount: null,
      },
      tagList: '',
    };
  },
  computed: {
    ...mapGetters([
      'getLangUUID',
    ]),
  },
  methods: {
    ...mapMutations([
      'setLangUUID',
    ]),
    changeNewsByTagData(slugName, currentPage) {
      this.NewsByTagData = [];
      this.SengdingNewsData = [];
      this.pageData = {
        currentPage: null,
        pageCount: null,
      };
      getNewsByTag(
        { slug: slugName, page: currentPage, language_id: this.getLangUUID },
        (response) => {
          response.data.data.data.forEach((elem) => {
            const temp = {
              titleEn: elem.slug,
              titleZh: elem.title,
              backgroundPic: elem.cover,
              content: `${elem.content.substring(0, 20)} ...`,
              slug: elem.slug,
              name: this.getTag(elem.tag_uuid),
              publish_day: elem.publish_day.substring(0, 10),
              month: this.getMonthSubstring(elem.publish_day),
            };
            this.NewsByTagData.push(temp);
          });
          this.NewsByTagDataMonthProcess();
          this.pageProcess(response.data.data);
          this.SendingDataProcess();
        },
      );
    },
    SendingDataProcess() {
      let count = 0;
      this.NewsByTagData.forEach((elem) => {
        if (elem.month !== '') {
          count += 1;
        }
        console.log(count);
      });
      const tempArr = [];
      for (let i = 0; i <= count - 1; i += 1) {
        tempArr.push([]);
      }
      let newCount = 0;
      this.NewsByTagData.forEach((elem) => {
        if (elem.month !== '') {
          tempArr[newCount].push(elem);
          newCount += 1;
        } else {
          tempArr[newCount - 1].push(elem);
        }
      });
      this.SengdingNewsData = tempArr;
    },
    NewsByTagDataMonthProcess() {
      console.log('start');
      let tempMonth = '';
      this.NewsByTagData.forEach((elem, index) => {
        console.log(elem, index);
        if (index !== this.NewsByTagData.length - 1) {
          if (elem.month === this.NewsByTagData[index + 1].month) {
            tempMonth = elem.month;
            this.NewsByTagData[index + 1].month = '';
          } else if (elem.month === '' && this.NewsByTagData[index + 1].month === tempMonth) {
            this.NewsByTagData[index + 1].month = '';
          }
        }
      });
    },
    pageProcess(pageData) {
      this.pageData = {
        currentPage: pageData.current_page,
        pageCount: pageData.last_page,
      };
    },
    getMonthSubstring(publishDay) {
      const month = publishDay.split('/')[1];
      let data;
      if (month === '01') {
        data = 'JAR';
      } else if (month === '02') {
        data = 'FEB';
      } else if (month === '03') {
        data = 'MAR';
      } else if (month === '04') {
        data = 'APR';
      } else if (month === '05') {
        data = 'MAY';
      } else if (month === '06') {
        data = 'JUN';
      } else if (month === '07') {
        data = 'JUL';
      } else if (month === '08') {
        data = 'AUG';
      } else if (month === '09') {
        data = 'SEP';
      } else if (month === '10') {
        data = 'OCT';
      } else if (month === '11') {
        data = 'NOV';
      } else if (month === '12') {
        data = 'DEC';
      }
      return data;
    },
    getTag(uuid) {
      let tagName;
      this.tagList.forEach((v) => {
        if (uuid === v.uuid) {
          tagName = v.name;
        }
      });
      return tagName;
    },
    getData() {
      getNewsTags(
        this.getLangUUID,
        (response) => {
          this.tagList = response.data.data;
          response.data.data.forEach((elem) => {
            const temp = {
              path: `/news/${elem.slug}/1`,
              linkName: elem.name,
            };
            this.Classification.push(temp);
          });
        },
      ).then(() => {
        this.changeNewsByTagData(this.tagSlug, this.$attrs.currentPage);
      });
    },
  },
  watch: {
    getLangUUID(val) {
      if (val !== null) {
        this.getData();
      }
    },
    $route(to, from) {
      // console.log(this.$route);
      // this.changeFliter();
      console.log(to, from);
      this.changeNewsByTagData(this.tagSlug, this.$attrs.currentPage);
    },
  },
  created() {
    console.log(this.$route.params);
    console.log(this.$route.query);
  },
  mounted() {
    if (this.getLangUUID !== null) {
      this.getData();
    }
  },
};
</script>
