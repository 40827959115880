<template>
  <section id="news_list">
    <div class="container w1300">
      <div class="fliter_outer">
        <MenuClassificationFilter
          :Classification="Classification" :tagSlug="tagSlug"/>
      </div>
      <div class="list_outer" style="padding-top:40px;">
        <!-- <div class="newsbox">
          <p class="deco_text">
            FEB
          </p>
          <router-link to="/NewsArticle/1" class="item">
            <div class="pic">
              <figure :style="{backgroundImage: `url(${require('@/assets/img/index/imglink_1_again.jpg')})`}"></figure>
            </div>
            <div class="text">
              <h6 class="txt-medium">【公告】防疫期間 就診/陪診須知</h6>
              <p class="date">
                2021-02-17
                <span class="tag txt-white small">院所公告</span>
              </p>
              <p class="context small txt-dark_gray">
                配合疫情指揮中心，凡至宜蘊醫療(宜蘊生殖醫學、宜蘊中醫)之就診、陪診、公務之民眾，請配合以下防疫措施...
              </p>
            </div>
          </router-link>
        </div> -->
        <div class="newsbox" v-for="(item, key) in SengdingNewsData" :key="key">
          <p class="deco_text">
            {{item[0].month}}
          </p>
          <span v-for="(monthData, key) in item" :key="key">
            <router-link :to='"/newsarticle/"+monthData.slug' class="item">
              <div class="pic">
                <figure :style="{backgroundImage: `url(${monthData.backgroundPic})`}"></figure>
              </div>
              <div class="text">
                <h6 class="txt-medium">{{monthData.titleZh}}</h6>
                <p class="date">
                  {{monthData.publish_day}}
                  <span class="tag txt-white small">{{monthData.name}}</span>
                </p>
                <p class="context small txt-dark_gray" v-html="monthData.content">
                </p>
              </div>
            </router-link>
          </span>
        </div>

        <div class="newsbox" v-if="NewsByTagData.length === 0">
          <h4 class="txt-gray txt-center">{{$t('notFound')}}</h4>
        </div>

        <!-- <div class="newsbox">
          <p class="deco_text">
            OCT
          </p>
          <router-link to="/NewsArticle/1" class="item">
            <div class="pic">
              <figure></figure>
            </div>
            <div class="text">
              <h6 class="txt-medium">11/20起，黃曉莉 醫師加入宜蘊生殖團隊</h6>
              <p class="date">
                <span class="tag txt-white small">院所公告</span>
                2020-07-20
              </p>
              <p class="context small txt-dark_gray">
                希望能夠幫助難孕夫妻早日成家，早日帶個健康寶寶回家。也因此李醫師決定從婦產科轉為不孕症專科醫師，目的就是想好好為求孕夫妻把關每一個環節，找出原因並解決問題
              </p>
            </div>
          </router-link>
        </div> -->
      </div>
    </div>
  </section>
</template>

<script>
import '@/assets/scss/news.scss';
import MenuClassificationFilter from '@/components/MenuClassificationFilter.vue';

export default {
  name: 'NewsList',
  components: {
    MenuClassificationFilter,
  },
  props: ['Classification', 'NewsByTagData', 'SengdingNewsData', 'tagSlug'],
  data() {
    return {
    };
  },
};
</script>
<style>
.context font {
  font: inherit;
}
</style>
