<template>
  <section id="pagination">
    <router-link :to="`/news/${tagSlug}/`+ (pageData.currentPage - 1 !== 0 ? pageData.currentPage - 1 : 1)" class="next">
      <img src="@/assets/img/arrow-l.svg" alt="">
    </router-link>
    <router-link :to="`/news/${tagSlug}/1`" class="page" v-if="pageData.pageCount > 4 && pageData.currentPage > 4">1</router-link>
    <span v-show="pageData.pageCount > 4 && pageData.currentPage !== 1  && pageData.currentPage > 5">...</span>
    <span v-for="(page, index) in pagination" :key="index">
      <span v-show="page.id === pageData.pageCount && page.id > 4 && pageData.pageCount - 3 > pageData.currentPage">...</span>
      <router-link :to="`/news/${tagSlug}/`+page.id" class="page" :class="{active: pageData.currentPage === page.id}" v-if="Math.abs(page.id - pageData.currentPage) < 4 || page.id == pageData.pageCount || pageData.pageCount == 0">{{page.id}}</router-link>
    </span>
    <!-- <router-link to="/" class="page">2</router-link>
    <router-link to="/" class="page">3</router-link>
    ...
    <router-link to="/" class="page">4</router-link> -->
    <router-link :to="`/news/${tagSlug}/`+ (pageData.currentPage + 1 > pageData.pageCount ? pageData.currentPage : pageData.currentPage + 1)" class="next">
      <img src="@/assets/img/arrow.svg" alt="">
    </router-link>
  </section>
</template>

<script>
export default {
  name: 'Pagination',
  props: ['pageData', 'tagSlug'],
  data() {
    return {
      pagination: [],
    };
  },
  methods: {
    getData() {
      this.pagination = [];
      for (let i = this.pageData.currentPage - 4; i <= this.pageData.pageCount; i += 1) {
        if (i > 0) {
          const data = { id: i };
          this.pagination.push(data);
        }
      }
    },
  },
  watch: {
    pageData() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
    console.log(this.$attrs.classification);
  },
};
</script>
